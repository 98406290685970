import React, { useContext, useEffect, useState } from 'react'
import { CiFacebook, CiLinkedin } from "react-icons/ci";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import logo from "../../src/Component/Image/Logo.png"
import { useNavigate } from 'react-router-dom';
import Context from '../Context/Context';
import { FaFacebook } from 'react-icons/fa6';
import x from "./Image/x.jpeg"

const Footer = () => {
  const { user, setUser } = useContext(Context);

  const [data, setData] = useState([]);

  const Navigate = useNavigate();


  useEffect(() => {

    getApi();
  }, [])

  const getCatApi = (data) => {
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch("https://api.placestovisitindia.com/api/focaaeegor", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "hhhhhhhh=>");
        const newEntry = {
          name: "Categories",
          onlyposts: result?.data
        };
        data.splice(1, 0, newEntry);
        setData(data)
      })
      .catch((error) => console.error(error));
  }

  const getApi = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch("https://api.placestovisitindia.com/api/getfooter", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "jjjjjjjj=>");
        // setData(result?.data);
        getCatApi(result?.data);
      })
      .catch((error) => console.error(error));
  }

  return (
    <div >

      <section className='py-3' style={{ backgroundColor: '#14265B' }}>
        <div className='container  px-3 my-4'>
          <div className='row'>
            <div className='col-lg-3 col-md-6 col-dm-12 mt-3'>
              <div>
                <h5 style={{ fontWeight: '700', textTransform: "capitalize", color: "#fff" }}>places to visit india</h5>
                <ul className='ps-0' style={{ textDecoration: 'none', listStyle: 'none', fontSize: '18px', fontWeight: "500", lineHeight: '38px', color: '#fff' }}>
                  <li style={{ cursor: "pointer", fontSize: "16px" }}>An Encyclopedia on Tourism</li>
                  <li style={{ cursor: "pointer", fontSize: "16px" }}>in India</li>
                </ul>
              </div>
            </div>

            {data?.slice(0, 3)?.map((res) => (
              <div className='col-lg-2 col-md-6 col-dm-12 mt-3' key={res?.name}>
                <h5 style={{ fontWeight: '700', color: "#fff" }}>{res?.name}</h5>
                <ul className='ps-0' style={{ textDecoration: 'none', cursor: "pointer", listStyle: 'none', fontSize: '18px', fontWeight: "500", lineHeight: '38px', color: "#fff" }}>
                  {res?.onlyposts?.map((res, index) => (
                    <li key={index} style={{ cursor: "pointer", fontSize: "16px" }} onClick={() => {
                      res?.post_slug ?   Navigate(`/${res?.post_slug}`) : Navigate(`/${res?.slug}`)
                      setUser(true);
                    }}>{res?.post_title || res?.name}</li>
                  ))}
                </ul>
              </div>
            ))}

            <div className='col-lg-3 col-md-6 col-dm-12 mt-3'>
              <div>
                <h5 style={{ fontWeight: '700', color: "#fff" }}>Quick Links</h5>
                <ul className='ps-0' style={{ textDecoration: 'none', listStyle: 'none', fontSize: '18px', fontWeight: "500", lineHeight: '38px', color: "#fff" }}>
                  <li style={{ cursor: "pointer", fontSize: "16px" }} onClick={() => { Navigate("/AboutUs") }}>About us</li>
                  <li style={{ cursor: "pointer", fontSize: "16px" }} onClick={() => { Navigate("/ContactUs") }}>Contact us</li>
                  <li style={{ cursor: "pointer", fontSize: "16px" }} onClick={() => { Navigate("/privacypolicy") }}>Privacy Policy</li>
                  <li style={{ cursor: "pointer", fontSize: "16px" }} onClick={() => { Navigate("/copyrightpolicy") }}>Copyright Policy</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='mt-5' style={{ borderBottom: "1px solid #fff" }}></div>
          <div className='row'>
            <div className='col-lg-8 col-md-6 col-dm-12 mt-3'>
              <h6 style={{ color: "#fff" }}>© 2024 Places-to-Visit-India all rights reserved.</h6>
            </div>
            <div className='col-lg-4 col-md-6 col-dm-12 mt-3'></div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Footer