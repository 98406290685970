
import React, { useEffect } from 'react';

const Sitemap = () => {
  useEffect(() => {
    const sitemapXML = `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
    <url>
        <loc>https://placestovisitindia.com/chopta-tunganath-chandrashila</loc>
        <lastmod>2024-10-29T05:51:42+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/chipla-kedar-trek-kumaon-uttarakhand</loc>
        <lastmod>2024-10-29T11:10:37+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/panchachuli-base-camp</loc>
        <lastmod>2024-11-01T05:43:34+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/kartik-swami-uttarakhand</loc>
        <lastmod>2024-05-15T16:25:49+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/kedarkantha-garhwal-uttarakhand</loc>
        <lastmod>2024-05-18T03:24:06+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/valley-of-flowers-garhwal-uttarakhand</loc>
        <lastmod>2024-06-01T05:54:41+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/hemkund-sahib-garhwal-uttarakhand</loc>
        <lastmod>2024-06-01T05:44:29+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/vyas-kund-himachal-pradesh</loc>
        <lastmod>2024-05-15T16:31:02+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/hampta-pass-himachal-pradesh</loc>
        <lastmod>2024-05-15T03:30:17+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/moon-peak-himachal-pradesh</loc>
        <lastmod>2024-05-15T05:07:02+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/triund-mcleodganj-dharmshala-himachal-pradesh</loc>
        <lastmod>2024-05-15T07:57:36+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/seoj-dhar-kailash-kund-jammu</loc>
        <lastmod>2024-05-15T08:35:19+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/kashmir-great-lakes</loc>
        <lastmod>2024-05-15T10:06:33+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/thajiwas-glacier-trek</loc>
        <lastmod>2024-05-15T10:26:34+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/tarsar-marsar-lake-trek-kashmir</loc>
        <lastmod>2024-05-15T10:43:02+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/tulian-lake-trek-kashmir</loc>
        <lastmod>2024-05-15T11:29:01+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/chadar-trek-ladakh</loc>
        <lastmod>2024-05-15T11:56:19+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/lasermo-la-trek-ladakh</loc>
        <lastmod>2024-05-15T12:33:56+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/markha-valley-trek-ladakh</loc>
        <lastmod>2024-05-15T16:22:05+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/rumtse-to-tso-moriri-trek-ladakh</loc>
        <lastmod>2024-05-16T04:25:28+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/sham-valley-trek-ladakh</loc>
        <lastmod>2024-06-02T04:11:16+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/anini-seven-lakes-trek-arunachal-pradesh</loc>
        <lastmod>2024-05-16T05:13:08+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/mawryngkhang-bamboo-trek</loc>
        <lastmod>2024-05-16T07:10:18+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/sandakphu-phalut-west-bengal</loc>
        <lastmod>2024-05-16T09:26:12+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/kalavantin-durg-trek-mumbai-maharashtra</loc>
        <lastmod>2024-05-16T09:52:57+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/kalsubai-peak-maharashtra</loc>
        <lastmod>2024-06-05T03:55:55+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/netravati-peak-trek-kudremukh-karnataka</loc>
        <lastmod>2024-05-16T12:48:03+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/adventure-in-rishikesh</loc>
        <lastmod>2024-09-26T12:10:22+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/manali-to-leh-bike-trip</loc>
        <lastmod>2024-05-16T13:45:22+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/bike-route-spiti-valley-Himachal-pradesh</loc>
        <lastmod>2024-05-18T06:44:58+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/dwarka-gujarat</loc>
        <lastmod>2024-05-17T05:11:13+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/rann-of-kachchh-gujarat</loc>
        <lastmod>2024-09-26T08:52:06+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/adi-kailash-aum-parvat</loc>
        <lastmod>2024-05-17T09:58:19+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/cherrapunji-meghalaya</loc>
        <lastmod>2024-05-17T11:32:56+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/rameshwaram-tamilnadu</loc>
        <lastmod>2024-05-17T13:23:29+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/spirituality-rishikesh-uttarakhand</loc>
        <lastmod>2024-05-18T09:07:56+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/kashi-aka-varanasi-uttarpradesh</loc>
        <lastmod>2024-09-26T10:48:12+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/destination-wedding-in-jaipur</loc>
        <lastmod>2024-05-18T11:18:51+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/gokarna-karnataka</loc>
        <lastmod>2024-05-18T13:21:48+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/sandhan-valley-maharashtra</loc>
        <lastmod>2024-05-24T13:32:26+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/adventure-in-coorg</loc>
        <lastmod>2024-09-26T12:10:53+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/ayodhya-uttar-pradesh</loc>
        <lastmod>2024-09-26T12:00:10+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/chembra-peak-trek-wayanad-kerala</loc>
        <lastmod>2024-06-02T03:45:55+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/khatu-shyam-ji-sikar-rajasthan</loc>
        <lastmod>2024-05-28T04:55:35+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/anamudi-peak-trek-munnar-kerala</loc>
        <lastmod>2024-06-02T03:50:16+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/golden-temple-amritsar-punjab</loc>
        <lastmod>2024-06-01T04:58:10+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/kashipur-niti-bike-route</loc>
        <lastmod>2024-06-01T12:01:04+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/sri-venkateswara-swamy-tirupati-balaji</loc>
        <lastmod>2024-08-24T09:51:56+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/beach-city-goa</loc>
        <lastmod>2024-09-26T08:56:27+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/adventure-in-goa</loc>
        <lastmod>2024-09-26T12:15:46+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/kumbhalgarh-fort-rajasthan</loc>
        <lastmod>2024-09-26T10:47:36+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/pinkcity-jaipur-rajasthan</loc>
        <lastmod>2024-09-26T08:58:08+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/hampi-karnataka</loc>
        <lastmod>2024-09-26T10:47:04+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/jagannath-puri-dham-odisha</loc>
        <lastmod>2024-06-18T11:22:28+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/tajmahal-agra-uttar-pradesh</loc>
        <lastmod>2024-09-26T10:36:39+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/mahabodhi-temple-bodhgaya-bihar</loc>
        <lastmod>2024-09-26T10:46:34+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/rani-ki-vav-patan-gujarat</loc>
        <lastmod>2024-06-24T15:41:22+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/ruins-of-nalanda-mahavihara-bihar</loc>
        <lastmod>2024-06-25T15:12:05+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/teerthraj-pushkar-rajasthan</loc>
        <lastmod>2024-06-27T08:01:33+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/amer-fort-jaipur-rajasthan</loc>
        <lastmod>2024-07-01T06:05:16+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/golden-fort-sonar-kila-jaisalmer-rajasthan</loc>
        <lastmod>2024-07-01T06:04:59+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/chand-baori-abhaneri-rajasthan</loc>
        <lastmod>2024-07-03T11:51:37+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/hadi-rani-ka-kund-todaraisingh-rajasthan</loc>
        <lastmod>2024-07-04T11:59:12+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/statue-of-unity-vadodara-gujarat</loc>
        <lastmod>2024-07-07T14:28:55+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/khajuraho-madhya-pradesh</loc>
        <lastmod>2024-07-08T10:19:42+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/elephanta-caves-mumbai</loc>
        <lastmod>2024-09-26T10:46:04+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/sanchi-stupa-madhya-pradesh</loc>
        <lastmod>2024-07-11T11:17:27+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/dhamek-stupa-sarnath-varanasi-uttar-pradesh</loc>
        <lastmod>2024-07-12T16:27:24+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/ajanta-ellora-caves-maharashtra</loc>
        <lastmod>2024-07-15T10:29:12+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/konark-sun-temple-odisha</loc>
        <lastmod>2024-07-16T12:32:07+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/jamiul-futuh-kozhikode-kerala</loc>
        <lastmod>2024-09-26T11:03:50+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/dargah-sharif-ajmer-rajasthan</loc>
        <lastmod>2024-07-22T07:25:52+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/mindrolling-monastery-dehradun-uttarakhand</loc>
        <lastmod>2024-07-23T16:15:33+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/st-philomenas-church-mysuru-karnataka</loc>
        <lastmod>2024-07-25T13:54:34+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/mata-vaishno-devi-dham-jammu</loc>
        <lastmod>2024-09-26T11:03:19+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/adventure-in-gulmarg-kashmir</loc>
        <lastmod>2024-09-26T12:17:47+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/mahakaleshwar-jyotirlinga-ujjain-madhya-pradesh</loc>
        <lastmod>2024-08-01T09:23:53+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/kedarnath-garhwal-uttarakhand</loc>
        <lastmod>2024-08-04T15:58:08+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/jawala-devi-kangra-himachal-pradesh</loc>
        <lastmod>2024-08-06T15:04:49+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/chamundeshwari-devi-mysore-karnataka</loc>
        <lastmod>2024-08-07T11:45:53+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/ranakpur-jain-temple-rajasthan</loc>
        <lastmod>2024-08-10T06:40:31+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/palitana-jain-tirth-bhavnagar-gujarat</loc>
        <lastmod>2024-08-12T04:48:02+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/hemis-monastery-leh-ladakh</loc>
        <lastmod>2024-08-13T10:42:48+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/tashiding-monastery-sikkim</loc>
        <lastmod>2024-08-15T16:10:40+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/aamby-valley-city-maharashtra</loc>
        <lastmod>2024-09-26T12:19:40+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/sri-bangla-sahib-delhi</loc>
        <lastmod>2024-08-21T10:41:11+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/haji-ali-dargah-mumbai-maharashtra</loc>
        <lastmod>2024-08-23T05:49:04+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/lakshadweep-india</loc>
        <lastmod>2024-08-27T10:54:03+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/sam-sand-dunes-rajasthan</loc>
        <lastmod>2024-08-29T07:58:05+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/cold-desert-nubra-valley-ladakh</loc>
        <lastmod>2024-08-31T13:24:34+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/dalhousie-chamba-himachal-pradesh</loc>
        <lastmod>2024-09-03T04:49:47+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/mechuka-arunachal-pradesh</loc>
        <lastmod>2024-09-05T07:34:07+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/vijaynagar-arunachal-pradesh</loc>
        <lastmod>2024-09-06T16:10:40+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/sural-bhatori-pangi-valley</loc>
        <lastmod>2024-09-09T13:24:44+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/lambasingi-andhra-pradesh</loc>
        <lastmod>2024-10-04T08:35:40+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/dhordo-rann-of-kachchh-gujarat</loc>
        <lastmod>2024-09-12T08:13:22+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/raghurajpur-craft-village</loc>
        <lastmod>2024-09-14T06:42:06+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/kullu-manali-himachal-pradesh</loc>
        <lastmod>2024-09-15T16:28:37+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/mahabaleshwar-maharashtra</loc>
        <lastmod>2024-09-20T03:47:06+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/darma-valley-uttarakhand</loc>
        <lastmod>2024-09-20T15:56:29+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/art-of-living-banglore</loc>
        <lastmod>2024-09-27T04:55:50+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/bungee-jumping-in-rishikesh</loc>
        <lastmod>2024-09-26T12:26:05+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/bungee-jumping-in-lonavala</loc>
        <lastmod>2024-09-23T12:42:01+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/dadu-temple-dudu-rajasthan</loc>
        <lastmod>2024-09-24T04:08:29+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/adventure-in-dandeli-karnataka</loc>
        <lastmod>2024-09-26T12:24:35+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/dhamma-bodhi-vipassana-centre-bodh-gaya-bihar</loc>
        <lastmod>2024-09-24T05:00:31+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/dhamma-giri-vipassana-centre-igatpuri-maharashtra</loc>
        <lastmod>2024-10-04T07:08:56+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/dhamma-pattana-vipassana-centre-mumbai</loc>
        <lastmod>2024-10-04T07:08:33+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/dol-ashram-almora-uttarakhand</loc>
        <lastmod>2024-10-27T05:56:07+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/gorakhnath-math-gorakhpur-uttar-pradesh</loc>
        <lastmod>2024-09-27T04:57:00+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/guru-ravidas-mandir-seer-goverdhanpur-varanasi</loc>
        <lastmod>2024-09-24T05:13:31+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/isha-foundation-coimbatore-tamilnadu</loc>
        <lastmod>2024-09-27T04:58:36+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/jim-corbett-national-park-uttarakhand</loc>
        <lastmod>2024-09-24T05:17:10+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/kabir-chaura-nath-varanasi</loc>
        <lastmod>2024-09-24T05:25:49+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/kalindi-khal-expedition-uttarakhand</loc>
        <lastmod>2024-10-03T17:15:21+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/kaziranga-national-park</loc>
        <lastmod>2024-09-24T05:50:19+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/j-krishnamurti-foundation-study-centre-chennai</loc>
        <lastmod>2024-09-27T05:00:26+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/mountain-biking-in-leh-ladakh</loc>
        <lastmod>2024-09-24T05:53:51+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/river-rafting-in-manali</loc>
        <lastmod>2024-09-24T05:57:04+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/mountain-biking-tehri-uttarakhand</loc>
        <lastmod>2024-09-24T06:00:22+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/nanda-devi-east-base-camp-trek-uttarakhand</loc>
        <lastmod>2024-09-24T06:02:02+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/ramana-maharshi-ashram-tiruvannamalai-tamilnadu</loc>
        <lastmod>2024-09-27T04:48:30+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/ranthambhore-national-park-rajasthan</loc>
        <lastmod>2024-09-24T06:06:26+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/rishi-valley-school-andhra-pradesh</loc>
        <lastmod>2024-09-24T06:20:17+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/river-rafting-in-ganges-rishikesh</loc>
        <lastmod>2024-09-24T06:22:08+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/satsang-foundation-banglore</loc>
        <lastmod>2024-09-24T06:23:52+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/scuba-diving-in-andaman-nicobar-islands</loc>
        <lastmod>2024-09-24T06:25:27+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/scuba-diving-in-lakshadweep</loc>
        <lastmod>2024-09-24T06:27:03+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/shri-govardhan-math-puri-odisha</loc>
        <lastmod>2024-09-24T06:28:53+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/the-valley-school-banglore-karnataka</loc>
        <lastmod>2024-09-24T06:30:42+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/the-yoga-institute-mumbai</loc>
        <lastmod>2024-09-24T06:32:10+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/jeeyar-math-rishikesh-uttarakhand</loc>
        <lastmod>2024-09-27T04:51:58+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/yogada-math-ranchi-bihar</loc>
        <lastmod>2024-09-24T07:23:55+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/yogada-math-noida-uttar-pradesh</loc>
        <lastmod>2024-09-24T07:25:22+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/yogada-satsang-kolkata-west-bengal</loc>
        <lastmod>2024-09-24T07:26:46+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/skiing-in-auli</loc>
        <lastmod>2024-10-09T11:52:31+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/skiing-in-gulmarg</loc>
        <lastmod>2024-09-24T10:08:20+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/parmarth-niketan-ashram-rishikesh-uttarakhand</loc>
        <lastmod>2024-09-24T10:40:50+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/acharya-prashant</loc>
        <lastmod>2024-09-24T11:22:02+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/gurudev-sri-sri-ravishankar</loc>
        <lastmod>2024-09-24T11:23:24+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/jaggi-vasudev-sadhguru</loc>
        <lastmod>2024-09-24T11:24:55+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/sri-madhukar-nath</loc>
        <lastmod>2024-09-24T11:26:56+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/bhrigu-lake-trek-himachal-pradesh</loc>
        <lastmod>2024-09-25T11:33:46+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/munnar-kerala</loc>
        <lastmod>2024-09-26T11:28:23+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/bandhavgarh-national-park</loc>
        <lastmod>2024-10-01T09:36:20+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/gir-national-park-gujarat</loc>
        <lastmod>2024-10-01T09:38:10+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/periyar-national-park-kerala</loc>
        <lastmod>2024-10-01T09:39:57+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/hemis-national-park</loc>
        <lastmod>2024-11-01T06:14:16+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/sundarban-national-park-west-bengal</loc>
        <lastmod>2024-11-01T06:14:58+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/auli-joshimath-uttarakhand</loc>
        <lastmod>2024-10-09T10:32:49+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/best-places-to-visit-kerala</loc>
        <lastmod>2024-10-14T03:35:46+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/kainchi-dham-neem-karauli-ashram-uttarakhand</loc>
        <lastmod>2024-10-25T10:52:33+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/trekking</loc>
        <lastmod>2024-05-18T05:02:07+00:00</lastmod>
        <changefreq>monthly</changefreq>
        <priority>0.6</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/heritage</loc>
        <lastmod>2024-07-16T12:07:57+00:00</lastmod>
        <changefreq>monthly</changefreq>
        <priority>0.6</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/pilgrimages</loc>
        <lastmod>2024-08-25T02:31:49+00:00</lastmod>
        <changefreq>monthly</changefreq>
        <priority>0.6</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/beaches-and-more</loc>
        <lastmod>2024-09-30T09:35:27+00:00</lastmod>
        <changefreq>monthly</changefreq>
        <priority>0.6</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/adventure</loc>
        <lastmod>2024-10-01T07:23:02+00:00</lastmod>
        <changefreq>monthly</changefreq>
        <priority>0.6</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/spirituality</loc>
        <lastmod>2024-09-24T11:33:32+00:00</lastmod>
        <changefreq>monthly</changefreq>
        <priority>0.6</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/art-and-culture-india</loc>
        <lastmod>2024-09-24T14:59:47+00:00</lastmod>
        <changefreq>monthly</changefreq>
        <priority>0.6</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/festivals-in-india</loc>
        <lastmod>2024-09-24T15:00:39+00:00</lastmod>
        <changefreq>monthly</changefreq>
        <priority>0.6</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/food-culture-in-india</loc>
        <lastmod>2024-09-24T15:01:26+00:00</lastmod>
        <changefreq>monthly</changefreq>
        <priority>0.6</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/colorful-traditions-in-india</loc>
        <lastmod>2024-09-24T15:05:23+00:00</lastmod>
        <changefreq>monthly</changefreq>
        <priority>0.6</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/religions-and-faiths-in-india</loc>
        <lastmod>2024-09-24T15:06:18+00:00</lastmod>
        <changefreq>monthly</changefreq>
        <priority>0.6</priority>
    </url>
    <url>
        <loc>https://placestovisitindia.com/science-and-technology-india</loc>
        <lastmod>2024-09-24T15:08:25+00:00</lastmod>
        <changefreq>monthly</changefreq>
        <priority>0.6</priority>
    </url>
</urlset>
    `;

    const blob = new Blob([sitemapXML], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    window.location.href = url;

    return () => URL.revokeObjectURL(url);
  }, []);

  return null; 
};

export default Sitemap;
